
import { computed, defineComponent, onMounted, ref, onUpdated } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import store from "@/store";
import { useI18n } from "vue-i18n";
import moment from "moment/moment";

interface IUser {
  _id: string;
  first_name: string;
  telegram_id: number;
  nickname?: string;
  last_use?: string;
  status: boolean;
  __v?: number;
}

export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    AddCustomerModal,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const search = ref<string>("");
    const { t } = useI18n();

    const searchItem = (item, searchValue) => {
      return item.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
    };

    const dateFormat = (date: string) => {
      return moment.unix(parseInt(date)).format('MMM DD, YYYY');
    }

    const usersList = computed(() => {
      const data = customers.value

      let arr: Array<IUser> = []
      data.forEach((item) => {
        if (
          searchItem(item.status, search.value) ||
          searchItem(item.first_name, search.value) ||
          searchItem(item.telegram_id, search.value) ||
          searchItem(item.nickname, search.value)
        ) {
          arr.push(item)
        }
      });
      return arr ? arr : []
    });

    const currentRouteName = computed(() => {
      return route.name;
    });

    const checkedCustomers = ref([]);
    const tableHeader = ref([
      {
        name: t("name"),
        key: "first_name",
        sortable: true,
      },
      {
        name: t("telegramID"),
        key: "telegram_id",
        sortable: true,
      },
      {
        name: t("nickname"),
        key: "nickname",
        sortable: true,
      },
      {
        name: t("lastUsed"),
        key: "last_use",
        sortable: true,
      },
      {
        name: t("status"),
        key: "status",
        sortable: true,
      },
    ]);

    const customers = ref<Array<IUser>>([
      /*{
        _id: Math.floor(Math.random() * 99999) + 1,
        first_name: "Emma Smith",
        telegram_id: "@test-telegram-ID-" + Math.floor(Math.random() * 99999) + 1,
        status: "active",
        last_use: "2021-12-06T10:06:57.898Z"
      },
      {
        _id: Math.floor(Math.random() * 99999) + 1,
        first_name: "Din Don",
        telegram_id: "@test-telegram-ID-" + Math.floor(Math.random() * 99999) + 1,
        status: "active",
        last_use: "2021-12-06T14:00:49.399Z",
      },
      {
        _id: Math.floor(Math.random() * 99999) + 1,
        first_name: "Dereck Whibley",
        telegram_id: "@test-telegram-ID-" + Math.floor(Math.random() * 99999) + 1,
        status: "active",
        last_use: "2021-12-09T15:20:40.957Z",
      },
      {
        _id: Math.floor(Math.random() * 99999) + 1,
        first_name: "Joe Peshie",
        telegram_id: "@test-telegram-ID-" + Math.floor(Math.random() * 99999) + 1,
        status: "inactive",
        last_use: "2021-12-06T07:17:15.221Z",
      },
      {
        _id: Math.floor(Math.random() * 99999) + 1,
        first_name: "Ronnie Swanson",
        telegram_id: "@test-telegram-ID-" + Math.floor(Math.random() * 99999) + 1,
        status: "active",
        last_use: "2021-12-09T15:20:40.957Z",
      },*/
    ]);
    const initCustomers = ref<Array<IUser>>([]);

    onMounted(() => {
      MenuComponent.reinitialization();
      initCustomers.value.splice(0, customers.value.length, ...customers.value);

      setCurrentPageBreadcrumbs("Dicionario", []);

      store.dispatch(Actions.GET_TELEGRAM_USERS)
        .then((response) => {

          customers.value = response.map(item => {
            return {
              _id: item._id,
              first_name: item.first_name,
              telegram_id: item.telegram_id,
              nickname: item.nickname,
              last_use: item.last_use,
              status: item.status,
              __v: item.__v
            }
          })
        })
    });

    const deleteFewCustomers = () => {
      checkedCustomers.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCustomers.value.length = 0;
    };

    const goDetails = (id) => {
      store.dispatch(Actions.FETCH_USER, { id: id }).then(() => {
        router.push({ name: "user", params: { id: id } });
      });
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < customers.value.length; i++) {
        if (customers.value[i]._id === id) {
          customers.value.splice(i, 1);
        }
      }
    };

    return {
      usersList,
      currentRouteName,
      customers,
      tableHeader,
      deleteCustomer,
      search,
      checkedCustomers,
      deleteFewCustomers,
      goDetails,
      dateFormat,
      t,
    };
  },
});
