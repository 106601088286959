<template>
    <UsersListingDicionario />
</template>

<script>
import UsersListingDicionario from '@/views/apps/customers/UsersListingDicionario.vue'
import {Actions} from "../../store/enums/StoreEnums";
import store from "@/store";

export default {
  name: "Dicionario",
  components: {
    UsersListingDicionario
  },
  created() {
    // fetch all users from api
    store.dispatch(Actions.FETCH_USERS)
  },
  /*beforeRouteUpdate: (to, from, next) => {
    store.dispatch(Actions.FETCH_USERS)
        .then(() => next())

  },*/
}
</script>

<style scoped>

</style>